import React, { useEffect } from 'react';
import Layout from '../../components/layout';
import SEO from "../../components/seo";

const IPBasics = () => {
  const seo = {
    metaDesc: 'IP Basics - Inventiv.org'
  }

  useEffect(() => {
    document?.body.classList.add('no-home');
  }, []);

  return (
    <Layout>
      <SEO title="IP Basics - Inventiv.org" canonical='/provisionalbuilder' seo={seo} />
      <section class="mt-3 mb-3 pt-3 pb-3 wow fadeInUp delay-04s">
        <div class="container ">
          <h1>1. Introduction to Inventiv</h1>
          <p class="f-16">
            Our mission is to democratize innovation: To create a more equitable and inclusive framework for everyone to solve problems using science and technology, and be rewarded for such innovations through the currency of the future—intellectual property (IP).  We provide tools and services that allow everyone, from individuals to corporations, large and small, to contribute to the long tail of IP.
            <br /><br />
            Our work has a disproportionately large effect on innovations because our tools catalytically protect the development and delivery of new technologies to the world.  One of our tools, ProvisionalBuilder™, is ideal for individual inventors and start-ups seeking to create IP assets immediately. ProvisionalBuilder™ is also ideal for university researchers and research teams who are preparing and filing provisional patent applications. Although prior experience with patenting is helpful, with ProvisionalBuilder™, it’s not necessary to generate a quality application that complies with the rules of most patent offices around the world.
          </p>
          <div>
            <p class="f-16"><strong>Table of Contents</strong></p>
            <p class="f-16" style={{ paddingLeft: 40 + 'px' }}><strong>1.1<em> &nbsp;</em><a href="#ipbasics" class="d-clr">IP Basics</a></strong></p>
            <p class="f-16" style={{ paddingLeft: 80 + 'px' }}><strong>1.1.1&nbsp; <a href="#copyright" class="d-clr">Copyright</a></strong></p>
            <p class="f-16" style={{ paddingLeft: 80 + 'px' }}><strong>1.1.2&nbsp; <a href="#trademark" class="d-clr">Trademark</a></strong></p>
            <p class="f-16" style={{ paddingLeft: 80 + 'px' }}><strong>1.1.3&nbsp; <a href="#tradesecret" class="d-clr">Trade secret</a></strong></p>
            <p class="f-16" style={{ paddingLeft: 80 + 'px' }}><strong><em>1.1.4</em>&nbsp; <a href="#unfaircompetition" class="d-clr">Unfair competition</a></strong></p>
            <p class="f-16" style={{ paddingLeft: 80 + 'px' }}><strong>1.1.5&nbsp; <a href="#patents" class="d-clr">Patents</a></strong></p>
            <p class="f-16" style={{ paddingLeft: 120 + 'px' }}><strong>1.1.5.1&nbsp; <a href="#designpatents" class="d-clr">Design patents</a></strong></p>
            <p class="f-16" style={{ paddingLeft: 120 + 'px' }}><strong>1.1.5.2&nbsp; <a href="#plantpatents" class="d-clr">Plant patents</a></strong></p>
            <p class="f-16" style={{ paddingLeft: 120 + 'px' }}><strong>1.1.5.3&nbsp; <a href="#utilitypatents" class="d-clr">Utility patents</a></strong></p>
            <p class="f-16" style={{ paddingLeft: 40 + 'px' }}><strong>1.2<em> &nbsp;</em><a href="#ipcategory" class="d-clr">How do I determine which IP category is best for me?</a></strong></p>
          </div>
          <h2 id="ipbasics">1.1 IP Basics</h2>
          <img src="../assets/img/banner/Picture1.png" width="100%" alt="" />
          <table class="table table-bordered mt-3">
            <tbody>
              <tr>
                <td colspan="2" width="623">
                  <ul style={{ listStyle: 'disc', paddingInlineStart: 40 + 'px' }}>
                    <li>Intellectual property (IP) refers to the creation of human intellect such as inventions, literary and artistic works, designs, and symbols, names, and images used in commerce.</li>
                    <li>IP is protected in law; they include:</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td width="312">Copyright</td>
                <td width="312">Original work of authorship</td>
              </tr>
              <tr>
                <td width="312">Trademark</td>
                <td width="312">Brand names, phrases, or designation</td>
              </tr>
              <tr>
                <td width="312">Trade Secret</td>
                <td width="312">Any secret information</td>
              </tr>
              <tr>
                <td width="312">Unfair competition</td>
                <td width="312">Deceptive practices by business</td>
              </tr>
              <tr>
                <td width="312">Patent</td>
                <td width="312">Invention</td>
              </tr>
            </tbody>
          </table>
          <p class="f-16">
            The Founding Fathers wanted to create a system that rewarded people for the fruits of their intellectual labor by granting them a monopoly over their intellectual products. They agreed that converting ideas and inventions into IP helps protect them from being misused, stolen, or lost. And IP allows people to reap the monetary rewards of their creations.
            <br />
            <br />
            There are various categories of IP, each offering its own form of protection.  Though the following categories are each discussed separately, be aware that sometimes your invention may fit into multiple IP categories.
          </p>
          <div class="d-flex justify-content-start" id="copyright">
            <div>
              <img src="../assets/img/logos/blue-copyright-sign-representing-patent-protection_MJbfXBvu-300x300.jpg" width="100px" alt="" />
            </div>
            <div>
              <h3 >1.1.1 Copyright</h3>
            </div>
          </div>
          <p class="f-16">
            Copyright laws protect an original work of authorship fixed in literary, audio-visual, or other media. The copyright owner has the exclusive right to reproduce, distribute, publicly perform, publicly display, or prepare derivative works of the copyrighted work. Copyright may be used to protect the original and creative way that ideas are expressed.  However, it does not protect ideas, procedures, processes, systems, methods of operation, concepts, principles, facts, or discoveries.
            <br /><br />
            Copyright protection begins as soon as the work assumes a tangible form. It lasts for the author’s lifetime plus 70 years. Regarding works for hire, copyright lasts 95 years after publication or 120 years after creation, whichever is longer. Though it is not necessary to register the original work to claim copyright protection, if you registered it before it is infringed or within three months after it is published or distributed, then you may have a more successful claim for statutory damages. These damages include payment for your attorney fees.
            <br /><br />
            Regarding copyright, in particular, the major advantage of this type of protection lies in its simplicity.
          </p>
          <ul style={{ listStyle: 'disc', paddingInlineStart: 40 + 'px' }}><li>Copyright protection does not depend on any formalities, such as registration or the deposit of copies in the 151 countries party to the Berne Convention for the Protection of Literary and Artistic Works. This means that international copyright protection is automatic—it begins as soon as a work is created.</li><li>A copyright owner enjoys a relatively long period of protection, which generally lasts for the life of the author plus 50 years or, in certain countries, 70 years after the author’s death. Compared with copyright protection, patent life is much shorter; it may last up to 20 years from the filing date of the application.</li></ul>
          <p class="f-16">
            Software can be patented or copyrighted. Copyright protection extends only to expressions and not to ideas, procedures, methods of operation, or mathematical concepts. Although copyright protects the literal “expression” of computer programs, it does not protect the “ideas” underlying the computer program, which often have considerable commercial value.
          </p>
          <div class="d-flex justify-content-start" id="trademark">
            <div>
              <img src="../assets/img/logos/blue-registered-sign-representing-patented-brands_My-lQrDO-300x300.jpg" width="100px" alt="" />
            </div>
            <div>
              <h3 >1.1.2 Trademark</h3>
            </div>
          </div>
          <p class="f-16">
            Trademarks are symbols or words that are routinely associated with a particular service or good.  Trademark law protects the trade identity and goodwill associated with goods and services marketed and sold by commercial entities.  It’s basically a form of consumer protection; it offers you protection from someone who uses your trademark in a manner that dilutes its power for a similar or even unrelated good or service.  Such use may confuse consumers. For example, if a dry cleaner uses the Nike swoosh on his/her marketing materials, consumers may be confused into believing there’s a legitimate relationship between Nike and the dry cleaner.
            <br /><br />
            Trademarks rights are protected under a patchwork of common law and federal law. You do not need to register your trademarks to exercise your right.  Ownership of a trademark, at least with respect to the geographic area of use, generally is established in the United States by actual “use” of the mark in connection with the sale of goods and services.  As long as the trademark is used, it is covered; for example, the Coca-Cola trademark has been used and protected for more than a hundred years.
          </p>
          <h3 id="tradesecret">1.1.3 Trade secret</h3>
          <p class="f-16">
            Trade secrets include confidential information, devices, formulas, or processes usually unknown to others that give you a competitive advantage.  For example, a trade secret may be a secret recipe that you expend a lot of energy and money to protect.
            <br /><br />
            Trade secret protection prevents others from using or transferring trade secrets without permission. Potentially, this protection can last forever, as long as the trade secrets retain economic value, they remain secret, and you take reasonable steps to preserve their secrecy.  However, once someone discovers the secret via reverse engineering or another legitimate manner, the trade secret is no longer protected.  In such cases, the trade secret owner cannot exercise any legal action against the discoverers, either.
            <br /><br />
            Trade secret protection may be a potential alternative to patent protection, if you do not want to reveal details about how to make your invention, for example.  Trade secrets are, of course, kept secret.  On the other hand, patents are not.  The USPTO usually publishes the patent application, so anyone can learn how to build your invention or operate it.
          </p>
          <h3 id="unfaircompetition">1.1.4 Unfair competition</h3>
          <p class="f-16">
            Unfair competition law protects against various deceptive practices by businesses.  Though the courts are still grappling with what this specifically means, unfair competition includes false advertising, deceptive packaging, and other dishonest actions.  For consumers, these laws protect them against companies that misrepresent what they do with a consumer’s personal information.
            <br /><br />
            If a judge finds someone in violation of this, he/she will issue an injunction.  This stops the violator from continuing to do what he/she did in violation.  It also defines what he/she can and cannot do.
          </p>
          <div class="d-flex justify-content-start" id="patents">
            <div>
              <img src="../assets/img/logos/patented-stamp-showing-registered-patent-or-trademarks_MkCEvVvu-300x300.jpg" width="100px" alt="" />
            </div>
            <div>
              <h3 >1.1.5 Patents</h3>
            </div>
          </div>
          <p class="f-16">
            The federal government issues patents.  Patents grant you the power over who makes, uses, imports, or sells a patented invention until the patent expires. Once the patent expires, the invention is in the public domain.  That means anyone can freely use the invention after the patent rights expire.
            <br /><br />
            There are three types of patents—design, plant, and utility.  The utility patent is the primary subject of this guide and will be discussed thoroughly later.  The following is a brief overview of each type.
          </p>
          <table class="table table-bordered mt-3">
            <tbody>
              <tr>
                <td width="326"><strong>Patent</strong></td>
                <td width="330"><strong>Protected Ideas</strong></td>
              </tr>
              <tr>
                <td width="326">Design Patent</td>
                <td width="330">Ornamental design on a useful item</td>
              </tr>
              <tr>
                <td width="326">Plant Patent</td>
                <td width="330">Plants produced by cuttings or non-sexual means</td>
              </tr>
              <tr>
                <td width="326">Utility Patent</td>
                <td width="330">A new machine, process, or system</td>
              </tr>
            </tbody>
          </table>
          <h4 id="designpatents">1.1.5.1 Design patents</h4>
          <p class="f-16">
            Design patents protect an invention’s new, original ornamental and aesthetic configuration. For example, a design patent may cover an icon for an application on a mobile device. It bars substantially similar designs from being manufactured, copied, imported, or used for 14 years after the patent’s issue date. This type of patent is generally less expensive than a utility patent, but it only protects a specific design.
            <br />
            Be aware that the design must not have any functional impact on the invention. If it does, then it should be protected by a utility patent, not a design patent.
          </p>
          <h4 id="plantpatents">1.1.5.2 Plant patents</h4>
          <p class="f-16">
            These are for new varieties of plants that are distinct and produced asexually, meaning plants that grow from cloning, grafts, or cuttings. This protection lasts 20 years from the patent application’s filing date.
          </p>
          <h4 id="utilitypatents">1.1.5.3 Utility patents</h4>
          <p class="f-16">
            Briefly, the government issues utility patents for inventions that carry out a utilitarian function in a novel, non-obvious manner. That basically means anything that humans invent, from Velcro fasteners to computer software, may be protected by utility patents.
            <br />
            After this patent is granted, the applicant has exclusive rights to the idea for a limited time. This protection may last for up to 20 years after the patent application’s filing date.
            <br />
            These patents have broad coverage. Hence, the work to get one is more onerous than a design patent and, thus, the process to obtain one may be more expensive.
          </p>
          <h2 id="ipcategory">1.2 How do I determine which IP category is best for me?</h2>
          <img src="../assets/img/banner/business-man-with-tablet-thinking-in-an-office_BYtjoC4i-e1602423200187.jpg" width="100%" alt="" />
          <table class="table table-bordered mt-1">
            <tbody>
              <tr>
                <td width="623">
                  <ul style={{ listStyle: 'disc', paddingInlineStart: 40 + 'px' }}>
                    <li>&nbsp;Intellectual property covers a vast number of intangible creations from names, visual signs, art, useful products, fashion, technical inventions, plants, to software.</li>
                    <li>Determine which intellectual property right is likely to be relevant to a particular object.</li>
                    <li>Each of these rights has a different set of rules. For example, the conditions for obtaining a patent are not the same when it comes to copyrights. The same goes for the duration of protection and formalities to apply.</li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
          <p class="f-16">
            Begin by analyzing what you want protected, how you want to protect it, and how long.  Again, many of these categories intersect, so you may be able to get multiple forms of protection if applicable, as well.  It’s best to consult with an attorney who is knowledgeable about your property and your situation.
          </p>
        </div>
      </section>
    </Layout>
  )
};

export default IPBasics;